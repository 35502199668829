
import { defineComponent, ref, onMounted, useAttrs, watch } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import { ApiBase, ApiExceptionHandlings, ApiSalesOrders } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { ExceptionHandlingTable } from "@/core/directive/interface/exceptionHandling";
import { getExceptionHandlingProcessMap } from "@/core/directive/function/exceptionHandling";
import { useRoute, useRouter } from "vue-router";
import { SalesOrderOverview } from "@/core/directive/interface/salesOrder";
import {
  commonChangeFilterAddDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "exception-handlings",
  components: {
    MBDatatable,
  },
  setup() {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数

    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const tableData = ref<Array<ExceptionHandlingTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("exceptionHandlings.id"),
        key: "id",
        sortable: true,
      },
      {
        name: t("exceptionHandlings.priority"),
        key: "level",
        sortable: false,
      },
      {
        name: t("exceptionHandlings.summary"),
        key: "title",
        sortable: false,
      },
      {
        name: t("salesOrder.orderNumber"),
        key: "sale_order_number",
        sortable: false,
      },
      {
        name: t("exceptionHandlings.date"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("exceptionHandlings.status"),
        key: "progress",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      type: [],
      progress: [],
      solution: [],
    });

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        const data = attrs.detailData as SalesOrderOverview;
        init(data);
      }
    );

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        const data = attrs.detailData as SalesOrderOverview;
        init(data);
      }
    });

    const getLabel = (val, key) => {
      return options.value[key].find((item) => {
        return item.value == val;
      })?.label;
    };

    const getCompanyData = async () => {
      loading.value = true;
      const { data } = await ApiSalesOrders.getCompanyInfo({
        order_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        if (data.data.sales_order_identifier) {
          filterOptions.value = [
            {
              field: "sale_order_number",
              value: data.data.sales_order_identifier.order_number,
            },
          ];
          getExceptionsList(
            currentPage.value,
            pageSize.value,
            search.value,
            filterOptions.value,
            sortOptions.value
          );
        }
      }
    };

    const init = (data) => {
      filterOptions.value = [
        {
          field: "sale_order_number",
          value: data.sales_order_identifier.order_number,
        },
      ];
      getExceptionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const getExceptionsList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiExceptionHandlings.getExceptionsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getExceptionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getExceptionsList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      getExceptionsList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getExceptionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "created_at_start") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "created_at_end") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "fulfillment_order_number") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getExceptionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getExceptionsList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getExceptionsList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      return getExceptionHandlingProcessMap(Number(status), t);
    };

    const getOptions = () => {
      ApiBase.getTaggingData({
        short_key: [
          "exception_handling_type",
          "exception_handling_progress",
          "exception_handling_solution",
        ],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.type = data.data.exception_handling_type.items;
            options.value.progress =
              data.data.exception_handling_progress.items;
            options.value.solution =
              data.data.exception_handling_solution.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      getOptions();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      route,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getExceptionsList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      getLabel,
      options,
    };
  },
});
