/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-12 08:28:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-09-12 08:32:56
 */
import { BadgeStyle, CommonLanguageType, CommonMapType } from "../type/common";
import { ExceptionHandlingProgress } from "../type/exceptionHandling";

export const getExceptionHandlingProcessMap = (
  status: number,
  language: CommonLanguageType
): CommonMapType => {
  const result = new Map([
    [
      ExceptionHandlingProgress.New,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightPrimary,
      },
    ],
    [
      ExceptionHandlingProgress.Processing,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightWarning,
      },
    ],
    [
      ExceptionHandlingProgress.WaitingFeedback,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightWarning,
      },
    ],
    [
      ExceptionHandlingProgress.Pending,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightWarning,
      },
    ],
    [
      ExceptionHandlingProgress.Approved,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightInfo,
      },
    ],
    [
      ExceptionHandlingProgress.Rejected,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightDanger,
      },
    ],
    [
      ExceptionHandlingProgress.Closed,
      {
        label: language("exceptionHandlings.process"),
        color: BadgeStyle.LightSuccess,
      },
    ],
  ]);
  return result.get(status);
};
