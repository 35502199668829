/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-12 08:24:11
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-09-12 08:26:26
 */
export enum ExceptionHandlingProgress {
  New = 10,
  Processing = 20,
  WaitingFeedback = 30,
  Pending = 40,
  Approved = 50,
  Rejected = 60,
  Closed = 70,
}
